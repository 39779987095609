:root {
  --shadow-color: 0deg 0% 0%;
  --shadow-elevation-low:
          0.3px 0.5px 0.4px hsl(var(--shadow-color) / 0.31),
          0.4px 0.8px 0.7px -2.5px hsl(var(--shadow-color) / 0.21),
          1.3px 2.5px 2.1px -5px hsl(var(--shadow-color) / 0.1);
  --shadow-elevation-medium:
          0.3px 0.5px 0.4px hsl(var(--shadow-color) / 0.33),
          0.5px 0.9px 0.8px -1.7px hsl(var(--shadow-color) / 0.25),
          2px 4.1px 3.4px -3.3px hsl(var(--shadow-color) / 0.16),
          6.3px 12.5px 10.5px -5px hsl(var(--shadow-color) / 0.08);
  --shadow-elevation-high:
          0.3px 0.5px 0.4px hsl(var(--shadow-color) / 0.3),
          0.4px 0.7px 0.6px -0.7px hsl(var(--shadow-color) / 0.27),
          1.1px 2.2px 1.8px -1.4px hsl(var(--shadow-color) / 0.23),
          3.2px 6.4px 5.4px -2.1px hsl(var(--shadow-color) / 0.19),
          7.2px 14.4px 12.1px -2.9px hsl(var(--shadow-color) / 0.15),
          13.8px 27.7px 23.2px -3.6px hsl(var(--shadow-color) / 0.11),
          23.7px 47.4px 39.7px -4.3px hsl(var(--shadow-color) / 0.08),
          37.5px 75px 62.9px -5px hsl(var(--shadow-color) / 0.04);
}
body {
  overscroll-behavior: contain;
}
.App {
  color: white;
  background-color: #282c34;
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.cardStack {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center;
  align-items: center; */
  margin: 0 auto;
  width: 500px;
  height: 500px;
}

.card {
  height: 112px;
  width: 80px;
  transition: .3s ease transform;
}
.shuffle{
  animation: shuffle .5s linear;
}
.card.regular {
  transition: transform .3s ease;
  transform: scale(1)
}
.card.stack {
  transform: scale(1.3) !important
}
.card.hidden {
  transform: scale(0) !important
}

.hand {
  width: 100%;
  height: 200px;
}

.animation {
  transition: .5s ease transform;
}

.handStack {
  position: absolute;
  bottom: 0;
  width: calc(100% - 8px - 4em) !important;
  margin: 2em;
}

.hidden{
    position: fixed;
    top: -100%;
}

/* Card Rotation Animation */
@keyframes shuffle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}