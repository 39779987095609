.szh-menu-container > ul {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 12px 0px;

	font-family: "Heebo-bold";
	font-style: normal;

	border-radius: 12px;

	background: var(--transp-20-60);
}

.szh-menu > li {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 4px 12px;
	gap: 8px;

	flex: none;
	order: 2;
	align-self: stretch;
	flex-grow: 0;
}

.szh-menu > li:hover {
	background: var(--transp-40-60);
}
