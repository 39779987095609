.hamburger {
    display: block;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 0px;
    border-radius: 16px;

    position: absolute;

    top: 8px;
    right: 20px;

    z-index: 2;
    background: var(--violet-prime-50);
}

.hamburgerItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 16px;

    flex: none;
    order: 1;
    align-self: stretch;
    cursor: pointer;
}

.hamburgerItem:active {
    background: var(--violet-70);
}

.hamburgerClose {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 8px 24px 16px 0px;
    gap: 8px;

}