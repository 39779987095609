.toggleOff {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px;
    
    position: relative;
    width: 52px;
    height: 32px;
    
    border-radius: 24px; 

    background: var(--vg-30);

    transition: background-color 50ms ease-in 50ms;
    cursor: pointer;
    border: 0.75px solid var(--border);
}

.toggleOn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px;
    
    position: relative;
    width: 52px;
    height: 32px;
    
    border-radius: 24px; 

    background: var(--violet-prime-50);

    transition: background-color 50ms ease-in 50ms;
    cursor: pointer;    
    border: 0.75px solid var(--border);
}

.toggleCircleOff {
    transform: translateX(0px);
    transition: transform 150ms ease-out;
    width: 28px;
    height: 28px;
    border-radius: 24px;
    background: var(--vg-0);
}

.toggleCircleOn {
    transform: translateX(19px);
    transition: transform 150ms ease-out ;
    width: 28px;
    height: 28px;
    border-radius: 24px;
    background: var(--vg-0);
}