@font-face {
    font-family: 'Heebo';
    src: url('/src/font/Heebo/Heebo-VariableFont_wght.ttf') format("truetype-variations");
}

@font-face {
    font-family: 'Heebo-bold';
    src: url('/src/font/Heebo/static/Heebo-Bold.ttf') format("truetype");
}

/* Headline / H1 */
.headline {
    font-family: 'Heebo-bold';
    font-style: normal;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 5%;
}

/* Body */
p {
    margin: 0;
    font-family: 'Heebo-bold';
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 2%;
}

a {
    color: var(--violet-prime-50)
}

/* Label */
label {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    padding: 0px 2px;
}

.tut {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;

    color: var(--violet-prime-50);
}