.labelItemGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  flex: none;
  flex-grow: 1;
}

.labelItemGroupContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
  align-self: stretch;
}

.labelIconCombo {
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 3px;
}

.settingsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 16px;
  gap: 16px;

  position: relative;
  margin: 0px 12.5px;
  min-height: 88px;

  background: var(--vg-10);
  border-radius: 24px;
}

.settingsContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  width: 100%;
  flex: content;
  order: 1;
  flex-grow: 0;
}

.settingsLabel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px 0px 0px;
  gap: 8px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  cursor: pointer;
}

.hairline {
  position: relative;
  width: 100%;
  border: 0.5px solid var(--vg-20);
  border-radius: 2px;
  align-self: stretch;
}

.player {
  display: flex;
  flex-direction: row;
  align-items: center;

  max-width: max-content;
  padding: 4px 12px 4px 4px;

  align-self: stretch;
  flex-grow: 1;

  background: var(--vg-20);
  border-radius: 8px;
}

.player p {
  margin-left: 8px;
}

.playerContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;

  width: 100%;
  flex-wrap: wrap;
}

.countTag {
  display: flex;
  flex-direction: row;
  background-color: var(--vg-20);
  border-radius: 4px;
  padding: 2px 8px;
  height: fit-content;
  margin-left: 40px;
}

.multiCardBox {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}

.miniCards:last-child {
  height: 56px;
  width: 40px;
  border-radius: 5px;
  background-color: var(--vg-0);
  margin-right: 0;
}

.miniCards {
  height: 56px;
  width: 40px;
  border-radius: 5px;
  background-color: var(--vg-0);
  margin-right: -32px;
}

.cardRow {
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.help {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border: 0.75px solid var(--border);
  color: var(--vg-0);
  background: var(--vg-50);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.help:active {
  background: var(--vg-70);
}

.help::before {
  content: "";
  position: absolute;
  width: 80px;
  height: 40px;
}

.helpExplanation {
  display: flex;
  padding: 8px;
  border-radius: 8px;
  background: var(--violet-30);
  color: var(--vg-0);
  border: 0.75px solid var(--border);

  position: absolute;
  width: max-content;
  max-width: 300px;
  margin: auto;
  z-index: 100;
  bottom: 24px;
}

@media (max-width: 540px) {
  .helpExplanation {
    left: -15vw;
    max-width: 80cw;
  }
}

@media (max-width: 400px) {
  .helpExplanation {
    left: -20vw;
    max-width: 60vw;
  }
}

.helpExplanation::before {
  content: "";
  position: fixed;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.helpContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-items: center;
  align-items: center;
  max-width: 300px;
}
