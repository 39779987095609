.gameHeader {
    display: flex;
    flex-direction: row;
    position: fixed;
    justify-content: space-between;
    align-items: center;

    top: 0px;
    height: 80px;
    width: 100%;
    padding: 16px 28px;
    border-radius: 0px 0px 24px 24px;

    background: var(--vg-20);
    z-index: 2;
  }

  .gameHeaderUnderBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;
    top: 0px;
    position: fixed;
  }

  .removedCards {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;

    position: relative;
    width: 100%;
    height: 224px;

    border-radius: 0px 0px 24px 24px;
    background: var(--vg-40);
    z-index: 1;

    transition: height 150ms ease-in;
  }


  .menuHeader {
    display: flex;
    flex-direction: row;
    position: fixed;
    justify-content: center;
    align-items: center;
    
    height: 80px;
    width: 100%;
    padding: 4px 28px;
    border-radius: 0px 0px 24px 24px;

    background: var(--vg-10);
    z-index: 2;
  }

  .gameHeaderContent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 4px;
    color: var(--violet-prime-50);
    }

    .lashHeader {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      padding: 4px 16px 8px;

      position: relative;
      height: 46px;
      width: 218px;
      left: 8px;
      transform: translateY(-12px);
  
      background: var(--violet-prime-50);
      color: var(--vg-0);
  
      border-radius: 0px 0px 16px 16px;
      z-index: 0;
  
      transition: transform 200ms ease-in;
      cursor: pointer;
  }
  
  .removedCardsUnder {
      height: 80px;
      transition: height 300ms cubic-bezier(.3, .7, .4, 1);
  }
  
  .lashHeaderUnder {
      transform: translateY(-12px);
      transition: transform 350ms cubic-bezier(.3, .7, .4, 1);
  }