
.btnBig { 
  stroke: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  border-radius: 24px;

  position: relative;
  width: auto;
  height: 80px;
  margin: 0px 12.5px 16px 12.5px;
  border: 0.75px solid var(--border);

  text-transform: uppercase;  

  cursor: pointer;
}

  .Primary {
    color: var(--vg-0);
    background: var(--violet-prime-50);
  }
  
  .Primary:active {
    background: var(--violet-70);
  }


  .Secondary {
    color: var(--vg-100);
    background: var(--vg-20);
  }
  
  .Secondary:active {
    background: var(--vg-30);
  }


  .btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;

    position: relative;
    border: 0.75px solid var(--border);

    cursor: pointer;
  }

  .medium {
    border-radius: 12px;
    padding: 12.5px 16px 11.5px;
    height: 48px;
  }

  .small {
    border-radius: 12px;
    padding: 9px 16px 8px;
    height: 40px;
  }

  .quadBtnSmall {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;

    position: relative;
    width: 40px;
    border: 0.75px solid var(--border);

    cursor: pointer;
  }
