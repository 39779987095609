.backgroundElement {
	background-color: var(--vg-10);
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: -1;
}

.cardContainer {
	position: relative;
	width: 80px;
	height: 112px;
	border-radius: 5px;

	background: var(--vg-0);
}

.playingArea {
	position: fixed;
	width: 100vw;
	max-width: min(726px, 100%);
	top: 7vh;
	height: calc(100vh - 224px);

	display: flex;
	justify-content: center;
	align-items: center;

	z-index: 0;
	overflow: auto;
}

.stackArea {
	display: grid;
	grid-template-columns: repeat(6, auto);
	grid-template-rows: repeat(3, auto);
	column-gap: 40px;
	row-gap: 16px;
	padding-right: 16px;

	justify-content: center;
	width: 272px;

	z-index: 1;
	overflow: visible;
}

.playingAreaColumn {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: fit-content;

	padding: 80px;

	gap: 16px;
}

.playingAreaRow {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	gap: 32px;
}

.cardDropZone {
	width: 80px;
	height: 112px;
	border-radius: 5px;

	opacity: 0.5;

	border: 3px solid var(--vg-30);
	background: none;
}

.cardDropZone:hover {
	width: 80px;
	height: 112px;
	border-radius: 5px;

	opacity: 0.5;

	border: 3px solid var(--violet-30);
	background: none;
}

.hand .cardDropZone {
	height: 129px;
	border: none;
}

.pileSize {
	position: relative;
	width: 32px;
	height: 23px;
	left: 76px;
	top: 4px;
	padding-left: 12px;

	font-size: 12px;
	font-weight: 500;
	line-height: 18px;
	display: flex;
	align-items: center;
	text-align: center;

	border-radius: 0px 8px 8px 0px;

	background-color: var(--vg-30);
	color: var(--vg-0);
}

.cardDropZone:hover > .pileSize {
	background-color: var(--violet-30);
}

.cardContainer:hover > .stackZone,
.cardContainer:hover > .spreadZone {
	display: block;
}

.stackZone {
	display: none;
	position: absolute;
	width: 46px;
	height: 112px;
	left: 0px;

	background: linear-gradient(0deg, rgba(219, 202, 255, 0.2), rgba(219, 202, 255, 0.2)), rgba(223, 223, 223, 0.4);

	border-radius: 5px 0px 0px 5px;
}

.stackZone:hover {
	background: rgba(152, 109, 242, 0.4);
}

.spreadZone {
	display: none;

	position: absolute;
	width: 38px;
	height: 112px;
	right: 0px;

	background: linear-gradient(0deg, rgba(219, 202, 255, 0.2), rgba(219, 202, 255, 0.2)), rgba(223, 223, 223, 0.4);
	backdrop-filter: blur(4px);

	border-radius: 5px;
}

.spreadZone:hover {
	background: rgba(152, 109, 242, 0.4);
	box-shadow: 0px 0px 4px 2px rgba(119, 65, 234, 0.3);
	backdrop-filter: blur(4px);
}
