.underBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    width: 100%;
    bottom: 0px;
    position: fixed;
}

.handUnderlaying {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    position: relative;
    width: 100%;
    height: 288px;

    border-radius: 24px 24px 0px 0px;
    background: var(--vg-40);
    z-index: 1;

    transition: height 150ms ease-in;
}

.hand {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    gap: 16px;
    isolation: isolate;

    position: fixed;
    width: 100% !important;
    height: 144px;
    bottom: 0px;

    border-radius: 24px 24px 0px 0px;
    background: var(--vg-20);    
    z-index: -1;
}

.tricks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    width: calc(100% - 32px);
    margin: 16px;
}

.lashHand {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 8px 16px 4px;

    position: relative;
    height: 46px;
    width: 140px;
    right: 8px;
    transform: translateY(12px);

    background: var(--violet-prime-50);
    color: var(--vg-0);

    border-radius: 16px 16px 0px 0px;
    z-index: 1;

    transition: transform 200ms ease-in;
    cursor: pointer;
}

.handUnderlayingUnder {
    height: 144px;
    transition: height 300ms cubic-bezier(.3, .7, .4, 1);
}

.lashHandUnder {
    transform: translateY(12px);
    transition: transform 350ms cubic-bezier(.3, .7, .4, 1);
}