.modalBackground {
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    height: 100vh;
    background: rgb(44, 40, 51, 0.5);
    padding: 0px 12.5px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    
}

.modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 16px;
    gap: 16px;

    position: relative;
    width: 100%;
    max-width: 380px;
    min-width: auto;

    background: var(--vg-10);

    border-radius: 24px;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;

    align-self: stretch;
}

input[type="text"] {
    width: 100%;
    height: 48px;
    background: var(--vg-20);
    border-radius: 8px;

    font-family: 'Heebo-bold';
    font-style: normal;
    font-size: 16px;
    line-height: 24px;

    padding: 0px 16px;

    border: none;
    color: var(--vg-100);
  }

  input[type="text"]:focus {
    outline: none;
    border: 2px solid var(--violet-prime-50);
    padding: 0px 14px;
  }

  .text-box {
    align-self: stretch;
    padding: 8px 16px;
    border-radius: 4px;
    width: fit-content;
    background-color: var(--vg-0);
  }

  .page-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .content-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding-bottom: 24px;
  }

  .tutorial-content {
    border: 0.75px solid var(--border);
    border-radius: 16px;
    width: 225px;
    height: 40vh;
    background-color: var(--vg-0);
  }

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    border: 0.75px solid var(--border);
    background-color: var(--vg-40);
    cursor: pointer;
  }