#basicDrop {
    /* box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25); */
    box-shadow:
    0px 0.2px 0.2px hsl(var(--shadow-color) / 0.11),
    0px 0.7px 0.8px -0.8px hsl(var(--shadow-color) / 0.11),
    -0.1px 1.7px 1.9px -1.7px hsl(var(--shadow-color) / 0.11),
    -0.1px 4.2px 4.7px -2.5px hsl(var(--shadow-color) / 0.11);
}

#dropSmall {
    /* box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25); */
    box-shadow:
    0px 0.2px 0.2px hsl(var(--shadow-color) / 0.1),
    0px 0.3px 0.3px -1.2px hsl(var(--shadow-color) / 0.1),
    0px 0.8px 0.9px -2.5px hsl(var(--shadow-color) / 0.1);
}

#transpBlur {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(4px);
}

#hardlineDrop {
    box-shadow: 0px 0px 6px 4px rgba(119, 65, 234, 0.5);
}